import React, { Component } from 'react';
import Scroll from 'react-scroll';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core';
import './Navigation.css';


interface NavigationState {
  value: string | boolean;
}

interface NavigationProps extends WithStyles<typeof styles> {
  version: "light" | "dark";
}

const styles = () => createStyles({
});

class Navigation extends Component<NavigationProps, NavigationState> {

  constructor(props: NavigationProps) {
    super(props);
  }

  onClickNav(selection: string) {
    Scroll.scroller.scrollTo(selection, { duration: 1500, delay: 0, smooth: 'easeInOutQuart' });
  }

  render() {
    return (
      <div className='navigation'>
        <ul className={'navlist ' + this.props.version}>
          <li className='firstItem'>
            <a onClick={() => this.onClickNav('home')}>
              home
            </a>
          </li>
          <li>
            <a onClick={() => this.onClickNav('about')}>
              about
            </a>
          </li>
          <li>
            <a onClick={() => this.onClickNav('projects')}>
              projects
            </a>
          </li>
          <li>
            <a onClick={() => this.onClickNav('contact')}>
              contact
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default withStyles(styles)(Navigation);
