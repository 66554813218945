import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import './Navigation.css';

interface HeaderProps extends WithStyles<typeof styles> { //RouteComponentProps<{}>,
}

const styles = ({ breakpoints }: Theme) => createStyles({
  greetings: {
    paddingLeft: '0px',
    [breakpoints.up('sm')]: {
      paddingLeft: '65px',
    },
    fontFamily: "'Raleway', sans-serif",
    color: '#352e34'
  },
  title: {
    fontFamily: "'Dosis', sans-serif",
    textDecoration: 'none',
    textAlign: 'center',
    padding: '10px',
    color: '#352e34'
  },
  sectionDesktop: {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'block',
    [breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  navigation: {
    bottom: 0,
    position: 'absolute'
  },
  wrapper: {
    position: 'absolute',
    width: '100%'
  }
});

class Header extends Component<HeaderProps, {}> {

  render() {
    const HomeLink = (props: any) => <Link to="/" {...props} />
    const { sectionDesktop, sectionMobile, title } = this.props.classes;

    return (
      <div>
        <div className={sectionDesktop}>
          <Typography
            variant="h3"
            color="inherit"
            className={title}
            align='center'
            component={HomeLink}>
            <b>Maude</b> Braunstein
            </Typography>
        </div>
        <div className={sectionMobile}>
          <Typography
            variant="h4"
            color="inherit"
            align='center'
            className={title}
            component={HomeLink}>
            <b>Maude</b> Braunstein
            </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Header);
