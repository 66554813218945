import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';
import { createStyles, Theme, WithStyles, withStyles, IconButton, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { Parallax, ParallaxLayer } from 'react-spring/addons';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';

interface ProjectsState {
}

interface ProjectsProps extends WithStyles<typeof styles> {
  height: number;
  width: number;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#352e34',
      contrastText: '#C7E6FC'
    }
  }
});

const styles = ({ breakpoints }: Theme) => createStyles({
  title: {
    fontFamily: "'Raleway', sans-serif",
    color: '#352e34',
    fontWeight: 'bolder'
  },
  subtitle: {
    fontFamily: "'Raleway', sans-serif",
    marginTop: '-15px',
    color: '#2196f3'
  },
  center: {
    textAlign: 'center'
  },
  centerImg: {
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      marginTop: '-15px'
    },
  },
  header: {
    padding: '0px',
    [breakpoints.up('sm')]: {
      padding: '0px 65px',
    },
    color: '#352e34'
  },
  text: {
    fontFamily: "'Raleway', sans-serif",
    color: '#352e34',
    fontWeight: 'lighter',
    marginLeft: '15px',
    [breakpoints.down('sm')]: {
      marginTop: '-15px'
    }
  },
  wrapper: {
    position: 'absolute',
    width: '100%'
  },
  buttonsP: {
    textAlign: 'center',
    marginTop: '-15px'
  },
  content: {
    width: '80%',
    display: 'inline-block'
  },
  buttonBlock: {
    width: '10%',
    display: 'inline-block'
  },
  projectTile: {
    padding: '55px',
    maxWidth: '45%',
    marginTop: '7%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [breakpoints.down('sm')]: {
      maxWidth: '90%',
      marginTop: '10%',
      padding: '0px'
    }
  }
});

class Projects extends Component<ProjectsProps, ProjectsState> {
  private parallax: Parallax | null = null;
  constructor(props: ProjectsProps) {
    super(props);
  }

  render() {
    const { title, text, wrapper, buttonsP, buttonBlock, content, projectTile, subtitle, centerImg } = this.props.classes;
    var height = this.props.height < 825 ? 825 : this.props.height;
    return (
      <section className='tile home' id='projects' style={{ minHeight: height }} >
        <MuiThemeProvider theme={theme}>
          <div className={wrapper} style={{ minHeight: height }}>
            <Parallax pages={3} scrolling={false} horizontal ref={ref => this.parallax = ref}>
              <ParallaxLayer offset={0} speed={0.5}>
                <div className={projectTile} id='projectsDiv'>
                  <div className={buttonBlock}>
                    <IconButton color='primary' onClick={() => (this.parallax!.scrollTo(2))}><ChevronLeft /></IconButton>
                  </div>
                  <div className={content}>
                    <p className={title}>Weather Window - December 29 2018 to January 5 2019:</p>
                    <p className={subtitle}>Status: Finished</p>
                    <p className={text}>
                      After setting up the backend for the revamped campaign page, I created a cat window for the first screen of this site which retrieves live weather
                      data for my city. The scene will change depending on the time of day and season as well.
                    </p>
                  </div>
                  <div className={buttonBlock}>
                    <IconButton color='primary' onClick={() => (this.parallax!.scrollTo(1))}><ChevronRight /></IconButton>
                  </div>
                  <p className={centerImg}><img src={require('../images/CatSample.gif')} /></p>
                </div>
              </ParallaxLayer>
              <ParallaxLayer offset={1} speed={0.5}>
                <div className={projectTile} id='projectsDiv'>
                  <div className={buttonBlock}>
                    <IconButton color='primary' onClick={() => (this.parallax!.scrollTo(0))}><ChevronLeft /></IconButton>
                  </div>
                  <div className={content}>
                    <p className={title}>Campaign Website - December 2018 to Present:</p>
                    <p className={subtitle}>Status: Going through a complete rebuild/redesign</p>
                    <p className={text}>
                      A website related to the Dungeons and Dragons campaign I run and manage for friends. Contains stats and data for the player characters, with game-related content,
                      resources, and historical information.
                    </p>
                    <p className={text}>
                      Also contains character biographies, with ability stats, spell descriptions, and character creation details. Uses <a href='https://open5e.com/' target='_blank'>Open5e</a> to retrieve
                      information about spells, races, and classes!
                    </p>
                  </div>
                  <div className={buttonBlock}>
                    <IconButton color='primary' onClick={() => (this.parallax!.scrollTo(2))}><ChevronRight /></IconButton>
                  </div>
                </div>
              </ParallaxLayer>
              <ParallaxLayer offset={2} speed={0.5}>
                <div className={projectTile} id='projectsDiv'>
                  <div className={buttonBlock}>
                    <IconButton color='primary' onClick={() => (this.parallax!.scrollTo(1))}><ChevronLeft /></IconButton>
                  </div>
                  <div className={content}>
                    <p className={title}>Discord Bot - August 2018 to Present:</p>
                    <p className={subtitle}>Status: !RemindMe feature, online hosting</p>
                    <p className={text}>
                      A bot created for the VoIP application Discord for friends. This bot has commands ranging from inside jokes and pictures of our pets
                      to more practical uses like for our Dungeons and Dragons games. It's a custom bot constantly changing based off requests from its users.
                      It responds to commands sent in a text channel or certain key words. This bot was built with Node JS.
                    </p>
                  </div>
                  <div className={buttonBlock}>
                    <IconButton color='primary' onClick={() => (this.parallax!.scrollTo(0))}><ChevronRight /></IconButton>
                  </div>
                  <p className={centerImg}><img src={require('../images/sitegif1.gif')} /></p>
                </div>
              </ParallaxLayer>
            </Parallax>
            <Navigation version='dark' />
            <div className='shape brown' />
          </div>
        </MuiThemeProvider>
      </section>
    );
  }
}

export default withStyles(styles)(Projects);