import * as api from './ApiCommon';
import { History } from 'history';
import { Weather } from '../interface/IWeather';

interface Cache {
  timestamp: number;
  item: any;
}

const retrieve = (
  callback: (value: any) => void,
  route: string,
  history: History) => {

  let headers = new Headers();
  headers.append('pragma', 'no-cache');
  headers.append('cache-control', 'no-cache');

  let init = {
    method: 'GET',
    headers: headers,
    credentials: 'include'
  } as RequestInit;

  fetch(route, init)
    .then(api.checkStatus)
    .then(api.toJson)
    .then(callback)
    .catch(e => api.handleApiError(e, history));
}

const retrieveCached = (
    callback: (value: any) => void,
    key: string,
    input: RequestInfo,
    history: History) => {
  
  let updateCache = true;
  const cachedItem = localStorage.getItem(key);
  // If our item exists in local storage use that version
  if (cachedItem) {
    let cache = JSON.parse(cachedItem) as Cache;
    callback(cache.item);
    // If our item is too old re-retrieve it from the server
    if (Date.now() - cache.timestamp < 600000) {
      updateCache = false;
    }
  }
  if (!updateCache) {
    return;
  }

  let init = {
    method: 'GET',
    credentials: 'include'
  } as RequestInit;

  fetch( input, init)
    .then(api.checkStatus)
    .then(api.toJson)
    .then(item => {
      callback(item);
      localStorage.setItem(key, JSON.stringify({timestamp: Date.now(), item}));
    })
    .catch(e => api.handleApiError(e, history));
};

export const RetrieveWeather = (callback: (value: Weather) => void, history: History) => (
  retrieveCached(callback, 'weather', '/api/weather', history)
);