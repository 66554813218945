import React from 'react';
export function snow() {
  return (
    <g>
      <g fill="#FFF" fillOpacity=".35" > 
        <g className="snow s-bottom-layer">
          <ellipse cx="88" cy="84.5" rx="6" ry="5.5" />
          <ellipse cx="263" cy="140.5" rx="6" ry="5.5" />
          <ellipse cx="326" cy="256.5" rx="6" ry="5.5" />
          <ellipse cx="22" cy="243.5" rx="6" ry="5.5" />
          <ellipse cx="11" cy="35.5" rx="6" ry="5.5" />
          <ellipse cx="85" cy="336.5" rx="6" ry="5.5" />
          <ellipse cx="175" cy="82.5" rx="6" ry="5.5" />
          <ellipse cx="126" cy="3.5" rx="6" ry="5.5" />
          <ellipse cx="312" cy="345.5" rx="6" ry="5.5" />
          <ellipse cx="312" cy="21.5" rx="6" ry="5.5" />
          <ellipse cx="346" cy="154.5" rx="6" ry="5.5" />
        </g>
      </g>
      <g fill="#FFF" fillOpacity=".42" transform='matrix(1, 0, 0, 1, 25, 25)'>> 
        <g className="snow s-middle-layer">
          <ellipse cx="88" cy="84.5" rx="6" ry="5.5" />
          <ellipse cx="263" cy="140.5" rx="6" ry="5.5" />
          <ellipse cx="326" cy="256.5" rx="6" ry="5.5" />
          <ellipse cx="22" cy="243.5" rx="6" ry="5.5" />
          <ellipse cx="11" cy="35.5" rx="6" ry="5.5" />
          <ellipse cx="85" cy="336.5" rx="6" ry="5.5" />
          <ellipse cx="175" cy="82.5" rx="6" ry="5.5" />
          <ellipse cx="126" cy="3.5" rx="6" ry="5.5" />
          <ellipse cx="312" cy="345.5" rx="6" ry="5.5" />
          <ellipse cx="312" cy="21.5" rx="6" ry="5.5" />
          <ellipse cx="346" cy="154.5" rx="6" ry="5.5" />
        </g>
      </g>
      <g fill="#FFF" fillOpacity=".5" >
        <g className="snow s-top-layer">
          <circle cx="67" cy="32" r="8" />
          <circle cx="195" cy="47" r="8" />
          <circle cx="260" cy="92" r="8" />
          <circle cx="48" cy="155" r="8" />
          <circle cx="126" cy="210" r="8" />
          <circle cx="263" cy="311" r="8" transform="rotate(180 874 1438)" />
          <circle cx="318" cy="172" r="8" transform="rotate(180 657 1256)" />
          <circle cx="339" cy="12" r="8" transform="rotate(180 443 1372)" />
          <circle cx="16" cy="323" r="8" transform="rotate(180 339 1107)" />
          <circle cx="10" cy="13" r="8" transform="rotate(180 24 1305)" />
          <circle cx="8" cy="8" r="8" />
          <circle cx="267" cy="244" r="8" />
          <circle cx="290" cy="176" r="8" />
          <circle cx="685" cy="347" r="8" />
        </g>
      </g>
    </g>
  );
}

export function rain() {
  var smallDrop = "M 91.344 187.382 " +
            "C 91.527 187.846 92.138 188.762 93.176 190.129 "+
            "C 94.302 191.539 93.48 193.639 91.696 193.91 "+
            "C 89.912 194.181 88.504 192.419 89.162 190.738 "+
            "C 89.247 190.518 89.366 190.313 89.513 190.129 " +
            "C 90.551 188.762 91.161 187.846 91.344 187.382 Z";
  var bigDrop = "M 217.39 241.588 "+
              "C 217.728 242.447 218.855 244.138 220.773 246.663 "+
              "C 222.853 249.268 221.336 253.148 218.04 253.65 "+
              "C 214.743 254.149 212.142 250.894 213.358 247.789 "+
              "C 213.516 247.383 213.735 247.004 214.007 246.663 "+
              "C 215.924 244.138 217.051 242.447 217.39 241.588 Z";
  return (
    <g>
      <g fill="#FFF" fillOpacity=".35" >
        <g className="rain r-bottom-layer">
          <path
            d={smallDrop}
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -50.436746, -148.33049)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 78.347255, -153.436464)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 219.612525, -89.328128)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 221.314516, 133.632719)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 90.828524, 141.008014)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 150.398216, -111.454014)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -58.946702, 145.546657)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -24.906878, -102.376728)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -76.533944, 20.733969)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 230.959133, 28.676595)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 259.325653, -182.370314)"
          />
        </g>
      </g>
      <g fill="#FFF" fillOpacity=".42" >
        <g className="rain r-middle-layer" transform='matrix(1, 0, 0, 1, 25, 25)'>
          <path
            d={smallDrop}
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -50.436746, -148.33049)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 78.347255, -153.436464)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 219.612525, -89.328128)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 221.314516, 133.632719)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 90.828524, 141.008014)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 150.398216, -111.454014)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -58.946702, 145.546657)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -24.906878, -102.376728)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -76.533944, 20.733969)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 230.959133, 28.676595)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 259.325653, -182.370314)"
          />
        </g>
      </g>
      <g fill="#FFF" fillOpacity=".42" >
        <g className="rain r-middle-layer second" transform='matrix(0.75, 0, 0, 0.75, -25, -25)'>
          <path
            d={smallDrop}
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -50.436746, -148.33049)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 78.347255, -153.436464)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 219.612525, -89.328128)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 221.314516, 133.632719)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 90.828524, 141.008014)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 150.398216, -111.454014)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -58.946702, 145.546657)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -24.906878, -102.376728)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, -76.533944, 20.733969)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 230.959133, 28.676595)"
          />
          <path
            d={smallDrop}
            transform="matrix(1, 0, 0, 1, 259.325653, -182.370314)"
          />
        </g>
      </g>
      <g fill="#FFF" fillOpacity=".5" >
        <g className="rain r-top-layer">
          <path
            d={bigDrop}
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -196.338235, 40.338977)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -187.260949, -76.531086)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -147.547821, -187.727844)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -117.47931, 70.974818)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, 102.644886, -226.873642)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, 108.31819, 88.562061)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -63.582922, -115.676883)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -48.832331, -224.60432)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -215.627469, -225.17165)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, 57.825784, -51.568548)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, 15.276004, -199.074452)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, 140.656022, -3.345464)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -45.995679, 69.840158)"
          />
          <path
            d={bigDrop}
            transform="matrix(1, 0, 0, 1, -115.777318, -90.147015)"
          />
        </g>
      </g>
    </g>
  );
}