export interface Palette {
  sky: string;
  sun: string;
  building: string;
  window: string;
  windowFrame: string;
  cloud: string;
  closeHill: string;
  midHill: string;
  farHill: string;
  tree1: string;
  tree2: string;
  trunk: string;
}

export enum TimeOfDay {
  Sunrise = 'Sunrise',
  Day = 'Day',
  Sunset = 'Sunset',
  Night = 'Night'
}

export enum Weather {
  Clear = 'Clear Skies',
  Cloudy = 'Cloudy',
  PartlyCloudy = 'Partly Cloudy',
  Rainy = 'Raining',
  Snowy = 'Snowing'
}