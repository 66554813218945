import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';
import { Parallax, ParallaxLayer } from 'react-spring/addons';
import { withStyles, WithStyles, createStyles, Theme, createMuiTheme, Button, MuiThemeProvider, Card } from '@material-ui/core';

interface AboutState {
}

interface AboutProps extends WithStyles<typeof styles> {
  height: number;
  width: number;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C7E6FC',
      contrastText: '#352e34'
    }
  }
});

const styles = ({ breakpoints }: Theme) => createStyles({
  header: {
    padding: '0px',
    [breakpoints.up('sm')]: {
      padding: '0px 65px',
    },
    fontFamily: "'Raleway', sans-serif",
    fontSize: 'large',
    fontWeight: 'bolder',
    textAlign: 'center',
    marginTop: '3em',
    color: '#2979ff'
  },
  center: {
    textAlign: 'center'
  },
  text: {
    fontFamily: "'Raleway', sans-serif",
    color: '#C7E6FC',
    fontWeight: 'lighter',
    position: 'absolute',
    width: '100%'
  },
  pBlock1: {
    borderStyle: 'dashed',
    maxWidth: '45%',
    padding: '75px',
    marginTop: '15%',
    [breakpoints.down('sm')]: {
      padding: '25px 5px',
      maxWidth: '85%',
      marginTop: '15%'
    }
  },
  pBlock2: {
    borderStyle: 'dashed',
    padding: '75px',
    maxWidth: '45%',
    marginTop: '15%',
    [breakpoints.down('sm')]: {
      padding: '4px',
      maxWidth: '92%',
      marginTop: '10%'
    }
  },
  buttons: {
    textTransform: 'initial'
  }
});

class About extends Component<AboutProps, AboutState> {
  private parallax: Parallax | null = null;
  constructor(props: AboutProps) {
    super(props);
  }

  render() {
    const { text, pBlock1, pBlock2, buttons, center } = this.props.classes;
    var height = this.props.height < 825 ? 825 : this.props.height;
    return (
      <section className='tile about' id='about' style={{ minHeight: height }} >
        <MuiThemeProvider theme={theme}>
          <div className={text} style={{ height: height }}>
            <Parallax pages={2} scrolling={false} ref={ref => this.parallax = ref}>
              <ParallaxLayer offset={0} speed={0.5}>
                <div id='pBlock' className={pBlock1}>
                  <p>
                    Hi, I'm Maude! I'm a born-and-raised Montrealer who's just wrapped up a computer science degree at Concordia University.
                    My passions include cats, coffee, code, and near-perfect alliteration.
                      </p>
                  <p>
                    I'm currently a full-time software developer with the goal of expanding my knowledge in web developement and machine learning in 2020. 
                  </p>
                  <p className={center}><Button className={buttons} variant="contained" color='primary' onClick={() => this.parallax!.scrollTo(1)}>More About Me</Button></p>
                </div>
              </ParallaxLayer>
              <ParallaxLayer offset={1} speed={0.5}>
                <div id='pBlock' className={pBlock2}>
                  <p>
                    I'm a Concordia University graduate with a Bachelor degree in Computer Science. I love puzzles and games, from video to tabletop,
                    and love to solve problems with the skills I'm learning in school, through personal projects, and through my professional career.
                    I've worked on projects that have tested my abilities in full stack development and software development,
                    and have met few challenges I haven't been able to conquer through determination and hard work.
                  </p>
                  <p>
                    I am a firm believer that with time and effort, there isn't much of a limit to what I can learn and what I might one day be interested in,
                    and I am always striving to learn new things about this field that has open so many doors for me. I feel as if with every day I improve,
                    I find something else to discover.
                  </p>
                  <p>
                    I've always let my interests guide me, and while it's taken me in some interesting directions, it's led me down a path I'm excited to continue to uncover.
                  </p>
                  <p className={center}><Button className={buttons} variant="contained" color='primary' onClick={() => this.parallax!.scrollTo(0)}>Back</Button></p>
                </div>
              </ParallaxLayer>
            </Parallax>
          </div>
          <Navigation version='light' />
          <div className='shape blue' />
        </MuiThemeProvider>
      </section>
    );
  }
}

export default withStyles(styles)(About);