import { History } from 'history';

export const checkStatus = (res: Response) => {
  if (!res.ok) {
    throw res;
  }

  return res;
};

export const toJson = (res: Response) => (
  res.json()
);

export const routeFromError = (res: Response, error: string) => {
  let msg = error ? '?msg=' + encodeURIComponent(error) : '';
  switch (res.status) {
    case 404:
      return '/404' + msg;
    case 500:
      return '/500';
    default:
      return '/';
  }
};

export const handleApiFail = (e: Response | Error, fail: (error: string) => void) => {
  
  if (e instanceof Response) {
    let res = e as Response;
    Promise.resolve(
      res.text()
    )
    .then ((body) => {        
      let j = JSON.parse(body);
      fail(j.message);
    })
    .catch(er => { //
    });
  } 
};

export const handleApiError = (e: Response | Error, history?: History) => {
  if (history) {
    if (e instanceof Response) {
      let res = e as Response;
      Promise.resolve(
        res.text()
      )
      .then ((body) => parseBody(res, body, history))
      .catch(err => history.push('/error', { error: true }));
    } else {
      history.push('/error', { error: true });
    }
  }
};

const parseBody = (res: Response, body: string, history: History) => {
  try {
    if (body.indexOf('ECONNREFUSED') >= 0) {
      history.push(
        routeFromError(res, 'Unable to connect. (ECONNREFUSED)'), 
        { error: true }
      );
    } else {
      let j = JSON.parse(body);
      if (j.message && !j.error) {
        history.push(routeFromError(res, j.message), { error: true });
      } else {
        history.push(routeFromError(res, j.error), { error: true });
      }
    }
  } catch (e) {
    history.push(routeFromError(res, body), { error: true });
  }
};