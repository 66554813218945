import React, { Component } from 'react';
import moment from 'moment-timezone';
import { History } from 'history';
import * as r from '../Retrievers/Retriever';
import Scene from './Scene/Scene';
import Navigation from '../Navigation/Navigation';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { TimeOfDay, Weather } from '../interface/IScene';
import { Weather as WeatherJSON } from '../interface/IWeather';
import '../Navigation/Navigation.css';
import Header from '../Navigation/Header';

interface HomeState {
  result: WeatherJSON | null;
  timeOfDay: TimeOfDay | null;
  weather: Weather | null;
  winter: boolean;
}

interface HomeProps extends WithStyles<typeof styles> {
  history: History;
  height: number;
  width: number;
  static: boolean;
}

const styles = ({ breakpoints }: Theme) => createStyles({
  greetings: {
    paddingLeft: '0px',
    [breakpoints.up('sm')]: {
      paddingLeft: '65px',
    },
    fontFamily: "'Raleway', sans-serif",
    color: '#352e34'
  },
  title: {
    fontFamily: "'Dosis', sans-serif",
    textDecoration: 'none',
    textAlign: 'center',
    padding: '10px',
    color: '#352e34'
  },
  sectionDesktop: {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'block',
    [breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  navigation: {
    bottom: 0,
    position: 'absolute'
  },
  wrapper: {
    position: 'absolute',
    width: '100%'
  }
});

class Home extends Component<HomeProps, HomeState> {
  static defaultProps = {static: false}
  constructor(props: HomeProps) {
    super(props);

    this.state = {
      result: null,
      timeOfDay: null,
      weather: null,
      winter: true
    };
    this.handleWeatherData = this.handleWeatherData.bind(this);
  }

  componentDidMount() {
    if (this.props.static) {
      var time = this.determineTime();
      this.setState({
        result: null,
        timeOfDay: time.timeOfDay,
        weather: Weather.Cloudy,
        winter: time.winter
      });
    } else {
      r.RetrieveWeather(result => this.handleWeatherData(result), this.props.history);
    }
  }

  handleWeatherData(result: WeatherJSON) {

    var weather = result.result.weather;
    var winter = false;
    var timeOfDay = TimeOfDay.Day;
    var w = null;

    var time = this.determineTime();
    timeOfDay = time.timeOfDay;
    winter = time.winter;

    if (weather.length === 1) {
      w = this.determineWeather(weather[0].id);
    } else {
      // IWeather.ts describes my chosen priority
      // Look through all the returned weather info and see if it contains a high priority ID
      var index = weather.findIndex(w => w.id >= 600 && w.id < 700);
      if (index < 0) {
        index = weather.findIndex(w => w.id >= 200 && w.id < 600);
      }
      if (index < 0) {
        index = weather.findIndex(w => w.id >= 700 && w.id < 800);
      }
      if (index < 0) {
        index = weather.findIndex(w => w.id === 803 || w.id === 804);
      }
      if (index < 0) {
        index = weather.findIndex(w => w.id === 801 || w.id === 802);
      }
      if (index < 0) {
        w === Weather.Clear;
      } else {
        w = this.determineWeather(weather[index].id);
      }
    }

    this.setState({
      result: result,
      timeOfDay: timeOfDay,
      weather: w,
      winter: winter
    });
  }

  render() {
    if (!this.state.weather || !this.state.timeOfDay) {
      return <div/>
    }
    var weather = this.state.weather as Weather;
    var timeOfDay = this.state.timeOfDay as TimeOfDay;
    const { wrapper } = this.props.classes;
    var height = this.props.height < 825 ? 825 : this.props.height;
    var temp;
    if (this.props.static) {
      temp = 0;
    } else {
      temp = this.state.result!.result.main.temp;
    }
    return (
      <section className='tile home' id='home' style={{ minHeight: height}} >
        <div className={wrapper} style={{height}}>
          <Header/>
            <Scene
              weather={weather}
              timeOfDay={timeOfDay}
              winter={this.state.winter}
              temp={temp}
              width={this.props.width}
              height={this.props.height}
              static={this.props.static}
            />
          <Navigation version='dark'/>
        </div>
      </section>
    );
  }

  determineWeather(weatherID: number) {
    if (weatherID >= 200 && weatherID < 600) {
      // Thunderstorm and rain variants
      return Weather.Rainy;
    } else if (weatherID >= 600 && weatherID < 700) {
      return Weather.Snowy;
    } else if ((weatherID >= 700 && weatherID < 800) || (weatherID === 803 || weatherID === 804)) {
      return Weather.Cloudy;
    } else if (weatherID === 801 || weatherID === 802) {
      return Weather.PartlyCloudy;
    } else {
      return Weather.Clear;
    }
  }

  determineTime() {
    var currentTime = moment(new Date()).tz("America/Toronto");
    var winter = false;
    var timeOfDay = TimeOfDay.Day;
    if (currentTime) {
      var month = currentTime.month();
      if (month === 10 || month === 11 || (month >= 0 && month < 3)) {
        winter = true;
      }
      var hour = currentTime.hour();
      if (winter) {
        if (hour === 16 || hour === 17) {
          timeOfDay = TimeOfDay.Sunset;
        } else if (hour === 6 || hour === 7) {
          timeOfDay = TimeOfDay.Sunrise;
        } else if (hour > 7 && hour < 16) {
          timeOfDay = TimeOfDay.Day;
        } else {
          timeOfDay = TimeOfDay.Night;
        }
      } else {
        if (hour === 19 || (hour === 20 && month !== 9) || (month === 9 && hour === 18)) {
          timeOfDay = TimeOfDay.Sunset;
        } else if (hour === 5 || hour === 6) {
          timeOfDay = TimeOfDay.Sunrise;
        } else if (hour > 6 && hour < 19) {
          timeOfDay = TimeOfDay.Day;
        } else {
          timeOfDay = TimeOfDay.Night;
        }
      }
    }
    return { timeOfDay, winter }
  }
}

export default withStyles(styles)(Home);