import * as React from 'react';

export class ErrorBoundary extends React.Component {
  
  state = {
    errorMessage: '',
    hasError: false
  };

  constructor(props: any) {
    super(props);
  }
 
  componentDidCatch(error: any) {
    this.setState({ 
      hasError: true, 
      errorMessage: error.message
    });
  }
 
  render() {
    if (this.state.hasError) {
      return(
        <div >
          <div>
            <h3>Error Caught: {this.state.errorMessage}</h3>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}