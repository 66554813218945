import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';
import { Email, Linkedin, GithubCircle } from 'mdi-material-ui';
import { withStyles, WithStyles, createStyles, Theme, createMuiTheme, MuiThemeProvider, IconButton } from '@material-ui/core';

interface ContactState {
}

interface ContactProps extends WithStyles<typeof styles> {
  height: number;
  width: number;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C7E6FC',
      contrastText: '#352e34'
    }
  }
});

const styles = ({ breakpoints }: Theme) => createStyles({
  text: {
    textAlign: 'center',
    color: '#C7E6FC',
    fontFamily: "'Raleway', sans-serif"
  },
  header: {
    fontFamily: "'Grand Hotel'",
    fontSize: '4.5em',
    marginTop: '20%',
    marginBlockEnd: '15px',
    [breakpoints.down('sm')]: {
      marginTop: '30%',
    },
  }
});

class Contact extends Component<ContactProps, ContactState> {
  constructor(props: ContactProps) {
    super(props);
  }

  render() {
    const { text, header } = this.props.classes;
    var height = this.props.height < 825 ? 825 : this.props.height;
    return (
      <section className='tile about' id='contact' style={{ minHeight: height }} >
        <MuiThemeProvider theme={theme}>
          <div className={text}>
            <p className={header}>Keep in Touch!</p>
            <p>
            <IconButton color="primary" aria-label="LinkedIn" href='https://www.linkedin.com/in/maudebraunstein/' target="_blank">
              <Linkedin />
            </IconButton>
            <IconButton color="primary" aria-label="GitHub" href='https://github.com/MauBrau/' target="_blank">
              <GithubCircle />
            </IconButton>
            <IconButton color="primary" aria-label="Email" href='mailto:maude.braunstein@gmail.com'>
              <Email />
            </IconButton>
            </p>
          </div>
          <Navigation version='light' />
          <div className='shape blue' />
        </MuiThemeProvider>
      </section>
    );
  }
}

export default withStyles(styles)(Contact);