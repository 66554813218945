import React from 'react';
import * as qs from 'query-string';
import { withRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';
import './App.css';
import { History } from 'history';
import Home from './Pages/Home';
import { ErrorBoundary } from './Pages/Error';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import Header from './Navigation/Header';
import { createMuiTheme, MuiThemeProvider, } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

interface AppState {
  height: number;
  width: number;
}

interface AppProps extends RouteComponentProps<{}> {
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C7E6FC',
      contrastText: '#352e34'
    },
    secondary: blue,
  }
});

class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    this.state = {
      height: 0,
      width: 0
    } as AppState;

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  render() {
    // render={({ match }) => Page(match.params.[param name], history)}
    let history: History = this.props.history;
    let params = qs.parse(this.props.location.search);
    var height = this.state.height;
    var width = this.state.width;
    return (
      <Root>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Switch>
              {/* Error handling*/}
              <Route exact={true} path="/" render={() => Main(history, height, width)} />
              <Route path="/400" render={() => Err400Page(this)} />
              <Route path="/401" render={() => Err401Page(this)} />
              <Route path="/404" render={() => NotFoundPage(params)} />
              <Route path="/500" render={() => ServerErrorPage()} />
              <Route path="/error" render={() => ErrorPage()} />
              <Route render={() => NotFoundPage(params)} />
            </Switch>
          </div>
        </MuiThemeProvider>
      </Root>
    );
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
  }
}

const Main = (history: History, height: number, width: number) => (
  <ErrorBoundary>
    <Home history={history} height={height} width={width} />
    <About height={height} width={width} />
    <Projects height={height} width={width} />
    <Contact height={height} width={width} />
  </ErrorBoundary>
);

const Err401Page = (app: App) => (
  <div>
    <Home history={app.props.history} height={app.state.height} width={app.state.width} static={true} />
    <About height={app.state.height} width={app.state.width} />
    <Projects height={app.state.height} width={app.state.width} />
    <Contact height={app.state.height} width={app.state.width} />
  </div>
);

const Err400Page = (app: App) => (
  <div>
    <Home history={app.props.history} height={app.state.height} width={app.state.width} static={true} />
    <About height={app.state.height} width={app.state.width} />
    <Projects height={app.state.height} width={app.state.width} />
    <Contact height={app.state.height} width={app.state.width} />
  </div>
);

const NotFoundPage = (params: any) => (
  <div>
    <Header />
    <h4 className='errorPageText'>Page Not Found {params['msg'] ? ' - ' + params['msg'] : null}</h4>
    <h5 className='errorPageText'><a href="/">Back</a></h5>
  </div>
);

const ServerErrorPage = () => (
  <div>
    <Header />
    <h4 className='errorPageText'>Internal Server Error Occurred</h4>
    <h5 className='errorPageText'><a href="/">Back</a></h5>
  </div>
);

const ErrorPage = () => (
  <div>
    <Header />
    <h4 className='errorPageText'>An Error Occurred</h4>
    <h5 className='errorPageText'><a href="/">Back</a></h5>
  </div>
);

const Root: React.SFC = (props) => (
  <div>{props.children}</div>
);
export default (withRouter<AppProps>(App));