import 'react-app-polyfill/ie11';
import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
const ReactModal = require('react-modal');
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactModal.setAppElement('#root');  

ReactDOM.render(
  <Router>
    <div className="wrapper">
      <App />
    </div>
  </Router>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();